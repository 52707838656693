<template>
  <div class="container_box">
    <div class="container_box_img">
      <div class="header">
        <div style="font-size: 0">
          <img src="../assets/liuliang.png" alt="" class="liuliang_icon" />
        </div>
        <div class="look_liuliang">看资讯免费得流量 , 全国通用不限速</div>
      </div>
    </div>
    <div class="main_center">
      <div class="main_center_box">
        <div class="container_phone">
          <div class="phone_box">
            <span class="phone_text">+86</span>
            <input
              type="text"
              placeholder="请输入手机号"
              class="phone_input"
              v-model="from.phone"
              maxlength="11"
              style="flex: 1"
            />
          </div>
          <div class="phone_box">
            <input
              type="text"
              placeholder="请输入验证码"
              class="phone_input"
              maxlength="6"
              v-model="from.msgCode"
            />

            <span class="scoden" v-if="timeLeft > 0"
              >{{ timeLeft }}s后获取</span
            >
            <span class="scoden" v-else @click="sendVerificationCode"
              >获取验证码</span
            >
          </div>
          <div class="phone_box">
            <input
              type="text"
              placeholder="请输入邀请码(选填)"
              class="phone_input"
              maxlength="10"
              v-model="from.invitedCode"
              style="flex: 1"
            />
          </div>
          <div class="footer_box" @click="onSubmit">登录/注册</div>
        </div>
      </div>
    </div>
    <div class="privacy">
      <van-checkbox
        v-model="isCheckAgreement"
        icon-size="0.45rem"
        label-disabled
        checked-color="#1563DD"
      >
        我已阅读并同意<span style="color: #1563dd">《法律条款及隐私协议》</span>
      </van-checkbox>
    </div>
  </div>
</template>

<script>
import { getAction, postAction } from "@/common/httpRequest";
import { isBlank } from "@/common/validatle";
export default {
  data() {
    return {
      from: {
        phone: "",
        msgCode: "",
        invitedCode: "",
      },

      timeLeft: 0,
      loadingForget: false,
      isCheckAgreement: false,
      downloadUrl: "", //登录成功之后跳转地址
    };
  },
  mounted() {
    this.address();
    this.from.invitedCode = this.$route.query.invitedCode;
  },
  methods: {
    address() {
      getAction("/chyinviteconfig/chyInviteConfig/noauth/queryConfig").then(
        (res) => {
          this.downloadUrl = res.data.result.downloadUrl;
        }
      );
    },
    //发送验证码
    sendCode() {
      let params = { phone: this.from.phone };
      postAction("/users/noauth/sendMsgCode", params).then((res) => {
        if (res.data.success) {
          this.$toast(res.data.message);
        } else {
          this.$toast(res.data.message);
          this.timeLeft = 0;
        }
      });
    },
    sendVerificationCode() {
      const phoneRege = /^1[3-9]\d{9}$/;
      if (!phoneRege.test(this.from.phone)) {
        return this.$toast("手机号不正确");
      }
      if (!this.isCheckAgreement) {
        this.$toast("请勾选我已阅读并同意");
        return;
      }
      if (this.timeLeft > 0) {
        return;
      }
      this.sendCode();
      this.timeLeft = 60;
      let interval = setInterval(() => {
        this.timeLeft--;
        if (this.timeLeft <= 0) {
          clearInterval(interval);
        }
      }, 1000);
    },
    //校验表单
    checkForm() {
      let mobile = this.from.phone;
      let code = this.from.msgCode;
      const phoneRege = /^1[3-9]\d{9}$/;
      if (!phoneRege.test(mobile)) {
        this.$toast("手机号不正确!");
        return false;
      }
      if (code.length < 6) {
        this.$toast("验证码不正确!");
        return false;
      }
      return true;
    },
    onSubmit() {
      // 去除邀请码中的空格
      // if (this.from.invitedCode) {
      //   this.from.invitedCode = this.from.invitedCode.trim();
      //   return;
      // }
      if (isBlank(this.from.phone)) {
        this.$toast("请输入手机号");
        return;
      }
      if (isBlank(this.from.msgCode)) {
        this.$toast("请输入验证码");
        return;
      }
      if (!this.isCheckAgreement) {
        this.$toast("请勾选我已阅读并同意");
        return;
      }
      let params = {
        phone: this.from.phone,
        msgCode: this.from.msgCode,
        invitedCode: this.from.invitedCode,
      };
      if (this.checkForm()) {
        if (this.loadingForget) return;
        this.loadingForget = true;
        postAction("/users/noauth/phoneLogin", params)
          .then((res) => {
            if (res.data.success) {
              console.log(res.data);
              localStorage.setItem("token", res.data.result.token);
              this.loadingForget = false;
              this.$toast(res.data.message);
              window.location.href = this.downloadUrl;
            } else {
              this.$toast(res.data.message);
              this.loadingForget = false;
            }
          })
          .catch((error) => {
            this.loadingForget = false;
            console.log(error);
          });
      }
    },
  },
};
</script>

<style>
.privacy {
  height: 34px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 24px;
  color: #93959c;
  line-height: 34px;
  text-align: center;
  position: absolute;
  bottom: 124px;
  left: 140px;
}
body {
  background: #fff;
  position: relative;
  /* height: 100vh; */
  /* border: 1px solid red; */
}
.container_box_img {
  background: url(../assets/bg.png) no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 512px;
  overflow: hidden;
}
.liuliang_icon {
  width: 424px;
  height: 66px;
}
.look_liuliang {
  width: 416px;
  height: 36px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 26px;
  color: #73798b;
  line-height: 36px;
  margin-top: 24px;
}
.header {
  margin: 52px 62px 0;
}
.main_center {
  position: relative;
  height: 800px;
  /* border: 1px solid red; */
}
.main_center_box {
  position: absolute;
  width: 100%;
  /* border: 1px solid #000; */
  top: -200px;
  background: #fff;
  border-radius: 30px;
  overflow: hidden;
}
.container_phone {
  margin: 80px 64px;
  border-radius: 12px;
  /* border: 1px solid pink; */
}
.phone_box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100px;
  background: #f4f6f7;
  border-radius: 12px;
  margin-bottom: 40px;
}
.phone_text {
  height: 44px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 32px;
  color: #93959c;
  line-height: 44px;
  padding: 0 20px;
  border-right: 1px solid #d8d8d8;
}
.phone_input {
  /* flex: 1; */
  height: 44px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 32px;
  color: #000;
  line-height: 44px;
  margin-left: 22px;
  border: none;
  background: #f4f6f7;
}
.phone_input::placeholder {
  color: #b9bbc2;
}

.scoden {
  height: 44px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 30px;
  color: #1563dd;
  line-height: 44px;
  text-align: right;
  font-style: normal;
  /* margin-right: 20px; */
  /* padding-right: 20px; */
  /* margin-left: 20px; */
  /* margin-right: 30px; */
  /* width: 500px; */
  /* border: 1px solid red; */
}
.footer_box {
  background: url(../assets/submit.png) no-repeat;
  background-size: 100% 100%;
  height: 100px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 32px;
  color: #ffffff;
  line-height: 100px;
  text-align: center;
}
</style>
