
import { getAction } from '../../common/httpRequest'
export default {
  namespaced: true,
  state: () => ({
    wxJsapid: localStorage.getItem('wxJsapid') || ''
    
  }),
  mutations: {
    GET_WXJSAPID(state,newWxJsapid) {
      state.wxJsapid = newWxJsapid
      localStorage.setItem('wxJsapid', newWxJsapid); // 将新值存储到本地存储中
      // console.log(state.wxJsapid, 'state.wxJsapid1....')
    },
   
  },
  actions: {
    /**
     *  获取微信appid
     */
    async useWxJsapid({ commit }) {
      const { data } = await getAction('/chywxgzhconfig/chyWxGzhConfig/noauth/getEnabledAppId');
      console.log(data)
      commit('GET_WXJSAPID', data.result);
      // localStorage.setItem('wxJsapid', data.result); // 手动更新本地存储中的值
    },
   
  }
}
