import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/Index.vue";
import wxAuth from "../views/wxAuth.vue";
import shipAuth from "../views/shipAuth.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "",
    name: "index",
    component: Index,
  },
  {
    path: "/wxAuth",
    name: "wxAuth",
    component: wxAuth,
  },
  {
    path: "/shipAuth",
    name: "shipAuth",
    component: shipAuth,
  },
  // app 下载页面
  {
    path: "/flowCard",
    name: "flowCard",
    component: () => import("../views/flow/flowCard"),
  },
  // app 内的 激活页面
  {
    path: "/flowActivation",
    name: "flowActivation",
    component: () => import("../views/flow/flowActivation.vue"),
  },
  // 反馈页面
  {
    path: "/Appfeedback",
    name: "Appfeedback",
    component: () => import("../views/flow/Appfeedback.vue"),
  },
  // app 购买页面
  {
    path: "/flowBuy",
    name: "flowBuy",
    component: () => import("../views/flow/flowBuy.vue"),
  },
  // 用户手册
  {
    path: "/Privacy",
    name: "Privacy",
    component: () => import("../views/flow/Privacy.vue"),
  },
  // app内支付成功之后 跳转页面
  {
    path: "/flowLoading",
    name: "flowLoading",
    component: () => import("../views/flow/flowLoading.vue"),
  },
  // 客服页面
  {
    path: "/service",
    name: "service",
    component: () => import("../views/flow/service.vue"),
  },
  // H5 设备激活
  {
    path: "/Mobiledevice",
    name: "Mobiledevice",
    component: () => import("../views/Mobile/Mobiledevice.vue"),
  },
  // H5 设备支付
  {
    path: "/Mobiledpay",
    name: "Mobiledpay",
    component: () => import("../views/Mobile/Mobiledpay.vue"),
  },
  // h5 分享
  {
    path: "/share",
    name: "share",
    component: () => import("../views/flowShare/share.vue"),
  },
  // app 签到页面
  {
    path: "/videoPlay",
    name: "videoPlay",
    component: () => import("../views/videoPlay/videoPlay.vue"),
  },
  // app 我的页面
  {
    path: "/myUser",
    name: "myUser",
    component: () => import("../views/myUser/myUser.vue"),
  },
  // 移动领取页面
  {
    path: "/flowList",
    name: "flowList",
    component: () => import("../views/flow/flowList.vue"),
  },
  // ios 视频激活
  {
    path: "/AppleVideo",
    name: "AppleVideo",
    component: () => import("../views/Mobile/AppleVideo.vue"),
  },
  // 安卓视频激活
  {
    path: "/AndroidVideo",
    name: "AppleVideo",
    component: () => import("../views/Mobile/AndroidVideo.vue"),
  },
  // 反馈
  {
    path: "/feedback",
    name: "feedback",
    component: () => import("../views/Mobile/feedback.vue"),
  },
  // 实名
  {
    path: "/Realname",
    name: "Realname",
    component: () => import("../views/Mobile/Realname.vue"),
  },

  // h5 支付宝引导页
  {
    path: "/against",
    name: "against",
    component: () => import("../views/Mobile/against.vue"),
  },
  {
    path: "/listed",
    name: "listed",
    component: () => import("../views/myUser/listed.vue"),
  },
  // 代理商页面
  {
    path: "/informationShow",
    name: "informationShow",
    component: () => import("../views/agent/informationShow.vue"),
  },

  // 代理商页面
  {
    path: "/signStatistics",
    name: "signStatistics",
    component: () => import("../views/agent/signStatistics.vue"),
  },
  // 代理商登录
  {
    path: "/login",
    name: "login",
    component: () => import("../views/agent/login.vue"),
  },
  // 信号检测
  {
    path: "/signal",
    name: "signal",
    component: () => import("../views/myUser/signal.vue"),
  },
  // 我的订单
  {
    path: "/myOrder",
    name: "myOrder",
    component: () => import("../views/myUser/myOrder.vue"),
  },
  // 58 元设备购买
  {
    path: "/purchase",
    name: "purchase",
    component: () => import("../views/limit/purchase.vue"),
  },
  // 收货地址
  {
    path: "/delivery",
    name: "delivery",
    component: () => import("../views/limit/delivery.vue"),
  },
  // 新增收货地址
  {
    path: "/addDelivery",
    name: "addDelivery",
    component: () => import("../views/limit/addDelivery.vue"),
  },
  //短剧视频
  {
    path: "/shortPlay",
    name: "shortPlay",
    component: () => import("../views/videoPlay/shortPlay.vue"),
  },
  // 短剧分享页面
  {
    path: "/shortShare",
    name: "shortShare",
    component: () => import("../views/videoPlay/shortShare.vue"),
  },
  // 短剧支付
  {
    path: "/shortPay",
    name: "shortPay",
    component: () => import("../views/videoPlay/shortPay.vue"),
  },
  //安卓短剧下载
  {
    path: "/AndroidDownload",
    name: "AndroidDownload",
    component: () => import("../views/videoPlay/AndroidDownload.vue"),
  },
  // 新的我的页面
  {
    path: "/myPage",
    name: "myPage",
    component: () => import("../views/newsPage/myPage.vue"),
  },
  // 代理的签到页面
  {
    path: "/agentVideoPlay",
    name: "agentVideoPlay",
    component: () => import("../views/videoPlay/agentVideoPlay.vue"),
  },
  //积分领取
  {
    path: "/integralPage",
    name: "integralPage",
    component: () => import("../views/videoPlay/integralPage.vue"),
  },
  // 影音娱乐
  {
    path: "/Audiovisual",
    name: "Audiovisual",
    component: () => import("../views/SecondaryPage/Audiovisual.vue"),
  },
  //商品详情
  {
    path: "/ProductDetails",
    name: "ProductDetails",
    component: () => import("../views/SecondaryPage/ProductDetails.vue"),
  },
  //优惠券
  {
    path: "/courtesyCard",
    name: "courtesyCard",
    component: () => import("../views/myUser/courtesyCard.vue"),
  },
  // 美食饮品
  {
    path: "/Foodrinks",
    name: "Foodrinks",
    component: () => import("../views/SecondaryPage/Foodrinks.vue"),
  },
  //工作生活
  {
    path: "/workLife",
    name: "workLife",
    component: () => import("../views/SecondaryPage/workLife.vue"),
  },
  // 说明书 我的
  {
    path: "/manual",
    name: "manual",
    component: () => import("../views/SecondaryPage/manual.vue"),
  },
  {
    path: "/instructions",
    name: "instructions",
    component: () => import("../views/SecondaryPage/instructions.vue"),
  },
  //wifi 设备
  {
    path: "/WiFidevice",
    name: "WiFidevice",
    component: () => import("../views/Mobile/WiFidevice.vue"),
  },
  // wifi 设备支付
  {
    path: "/Wifipay",
    name: "Wifipay",
    component: () => import("../views/Mobile/Wifipay.vue"),
  },
  // 代理下载页面
  {
    path: "/ProxyDownload",
    name: "ProxyDownload",
    component: () => import("../views/agent/ProxyDownload.vue"),
  },
  //代理商激活
  {
    path: "/Proxydevice",
    name: "Proxydevice",
    component: () => import("../views/agent/Proxydevice.vue"),
  },
  // 代理商支付
  {
    path: "/Proxypay",
    name: "Proxypay",
    component: () => import("../views/agent/Proxypay.vue"),
  },
  // 自动识别教程
  {
    path: "/VideoTutorials",
    name: "VideoTutorials",
    component: () => import("../views/Mobile/VideoTutorials.vue"),
  },
  // 金麦设备
  {
    path: "/JinmaiEquipment",
    name: "JinmaiEquipment",
    component: () => import("../views/Mobile/JinmaiEquipment.vue"),
  },
  // 金麦支付
  {
    path: "/JinmaiPayment",
    name: "JinmaiPayment",
    component: () => import("../views/Mobile/JinmaiPayment.vue"),
  },
  // 会员开通
  {
    path: "/Membership",
    name: "Membership",
    component: () => import("../views/SecondaryPage/Membership.vue"),
  },
  // 会员开通h5
  {
    path: "/Membershipv2",
    name: "Membershipv2",
    component: () => import("../views/SecondaryPage/Membershipv2.vue"),
  },
  // 无兑换权限跳转会员开通页面
  {
    path: "/Membership_interior",
    name: "Membership_interior",
    component: () => import("../views/SecondaryPage/Membership_interior.vue"),
  },
  // 原生跳转兑换页
  {
    path: "/ProductDetails_interior",
    name: "ProductDetails_interior",
    component: () =>
      import("../views/SecondaryPage/ProductDetails_interior.vue"),
  },
  // 领会员跳转兑换页
  {
    path: "/ProductDetailsv2",
    name: "ProductDetailsv2",
    component: () => import("../views/SecondaryPage/ProductDetailsv2.vue"),
  },
  // 原生跳转影音娱乐
  {
    path: "/Audiovisualv2",
    name: "Audiovisualv2",
    component: () => import("../views/SecondaryPage/Audiovisualv2.vue"),
  },
  // 领会员
  {
    path: "/Leadmembers",
    name: "Leadmembers",
    component: () => import("../views/Leadmembers/Leadmembers.vue"),
  },
  // 会员我的页面
  {
    path: "/mine",
    name: "mine",
    component: () => import("../views/Leadmembers/mine.vue"),
  },
  // 会员订单
  {
    path: "/memberOrders",
    name: "memberOrders",
    component: () => import("../views/Leadmembers/memberOrders.vue"),
  },
  // 会员下载地址
  {
    path: "/equityDownload",
    name: "equityDownload",
    component: () => import("../views/Leadmembers/equityDownload.vue"),
  },
  // 会员落地页
  {
    path: "/EquityPage",
    name: "EquityPage",
    component: () => import("../views/Leadmembers/EquityPage.vue"),
  },
  {
    path: "/EquityPageTikTok:prifix([0-9][0-9][0-9][0-9]):saleGroup(.*)",
    name: "EquityPage",
    component: () => import("../views/Leadmembers/EquityPage.vue"),
  },
  {
    path: "/EquityPageTikTok(.*)",
    name: "EquityPage",
    component: () => import("../views/Leadmembers/EquityPage.vue"),
  },

  // 支付成功之后跳转地址
  {
    path: "/downloadLink",
    name: "downloadLink",
    component: () => import("../views/Leadmembers/downloadLink.vue"),
  },
  // 邀请列表
  {
    path: "/myInvitation",
    name: "myInvitation",
    component: () => import("../views/Leadmembers/myInvitation.vue"),
  },
  // 我的邀请
  {
    path: "/invitationPage",
    name: "invitationPage",
    component: () => import("../views/Leadmembers/invitationPage.vue"),
  },
  // 测试会员支付地址
  {
    path: "/testPayment",
    name: "testPayment",
    component: () => import("../views/Leadmembers/testPayment.vue"),
  },
  // 优惠87元

  {
    path: "/preferential",
    name: "preferential",
    component: () => import("../views/Leadmembers/preferential.vue"),
  },
  // 办公会员落地页
  {
    path: "/officePageLdy",
    name: "officePageLdy",
    component: () => import("../views/Leadmembers/officePageLdy.vue"),
  },
  // TV 会员落地页
  {
    path: "/tvPageLdy",
    name: "tvPageLdy",
    component: () => import("../views/Leadmembers/tvPageLdy.vue"),
  },
  //办公下载地址
  {
    path: "/officeDownload",
    name: "officeDownload",
    component: () => import("../views/Leadmembers/officeDownload.vue"),
  },
  {
    path: "/officeDownloadLick",
    name: "officeDownloadLick",
    component: () => import("../views/Leadmembers/officeDownloadLick.vue"),
  },
  // tv 下载地址
  {
    path: "/tvDownload",
    name: "tvDownload",
    component: () => import("../views/Leadmembers/tvDownload.vue"),
  },
  {
    path: "/tvDownloadLick",
    name: "tvDownloadLick",
    component: () => import("../views/Leadmembers/tvDownloadLick.vue"),
  },
  // 话费充值页面
  {
    path: "/RechargePhone",
    name: "RechargePhone",
    component: () => import("../views/Leadmembers/RechargePhone.vue"),
  },
  // 畅享通讯领会员页面
  {
    path: "/communication",
    name: "communication",
    component: () => import("../views/Leadmembers/communication.vue"),
  },
  // 话费权益
  {
    path: "/phonePage",
    name: "phonePage",
    component: () => import("../views/Leadmembers/phonePage.vue"),
  },
  {
    path: "/phonePage:prifix([0-9][0-9][0-9][0-9]):saleGroup(.*)",
    component: () => import("../views/Leadmembers/phonePage.vue"),
  },
  {
    path: "/phonePage(.*)",
    component: () => import("../views/Leadmembers/phonePage.vue"),
  },
  {
    path: "/phoneDownload",
    name: "phoneDownload",
    component: () => import("../views/Leadmembers/phoneDownload.vue"),
  },
  {
    path: "/phoneDownloadLick",
    name: "phoneDownloadLick",
    component: () => import("../views/Leadmembers/phoneDownloadLick.vue"),
  },
  // 畅享通讯的
  {
    path: "/phoneMyuser",
    name: "phoneMyuser",
    component: () => import("../views/Leadmembers/phoneMyuser.vue"),
  },
  // 畅享通讯话费权益
  {
    path: "/expensePhone",
    name: "expensePhone",
    component: () => import("../views/Leadmembers/expensePhone.vue"),
  },
  // 畅享通讯APP 版本 话费购买页
  {
    path: "/phoneApp",
    name: "phoneApp",
    component: () => import("../views/Leadmembers/phoneApp.vue"),
  },
  // 产品列表
  {
    path: "/productList",
    name: "productList",
    component: () => import("../views/limit/productList.vue"),
  },
  // 提现页面资料
  {
    path: "/document",
    name: "document",
    component: () => import("../views/Leadmembers/document.vue"),
  },
  // 邀请列表
  {
    path: "/invitePage",
    name: "invitePage",
    component: () => import("../views/Leadmembers/invitePage.vue"),
  },
  {
    path: "/phoneOnly",
    name: "phoneOnly",
    component: () => import("../views/Leadmembers/phoneOnly.vue"),
  },
  //好物
  {
    path: "/Takegoods",
    component: () => import("../views/commodity/Takegoods.vue"),
  },
  {
    path: "/Takeinformation",
    component: () => import("../views/commodity/Takeinformation.vue"),
  },
  {
    path: "/memberOne",
    component: () => import("../views/commodity/memberOne.vue"),
  },
  {
    path: "/memberCash",
    component: () => import("../views/commodity/memberCash.vue"),
  },
  {
    path: "/exclusivePage",
    component: () => import("../views/commodity/exclusivePage.vue"),
  },
   {
    path: "/memberUser",
    component: () => import("../views/commodity/memberUser.vue"),
  },
  {
    path: "/newlyCaimed",
    component: () => import("../views/NewPages/newlyCaimed.vue"),
  },
  {
    path: "/convertPage",
    component: () => import("../views/NewPages/convertPage.vue"),
  },
  {
    path: "/choicePage",
    component: () => import("../views/NewPages/choicePage.vue"),
  },
  {
    path: "/determinePage",
    component: () => import("../views/NewPages/determinePage.vue"),
  },
   {
    path: "/newsUser",
    component: () => import("../views/NewPages/newsUser.vue"),
  },
  {
    path: "/EquityPageZzs",
    component: () => import("../views/zzsOnly/EquityPageZzs.vue"),
  },
  {
    path: "/phoneZzs",
    component: () => import("../views/zzsOnly/phoneZzs.vue"),
  },
  {
    path: "/tipsPage",
    component: () => import("../views/zzsOnly/tipsPage.vue"),
  },
  {
    path: "/phoneTips",
    component: () => import("../views/zzsOnly/phoneTips.vue"),
  },
   {
    path: "/yzzOne",
    component: () => import("../views/yzzOnly/yzzOne.vue"),
  },
    {
    path: "/yzzTwo",
    component: () => import("../views/yzzOnly/yzzTwo.vue"),
  },
    {
    path: "/yzzdownLick",
    component: () => import("../views/yzzOnly/yzzdownLick.vue"),
  },
    {
    path: "/yzzdownLickTwo",
    component: () => import("../views/yzzOnly/yzzdownLickTwo.vue"),
  },
    {
    path: "/TmallPage",
    component: () => import("../views/yzzOnly/TmallPage.vue"),
  },
    {
    path: "/Tmalldown",
    component: () => import("../views/yzzOnly/Tmalldown.vue"),
  },
   
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: "history",
  routes,
});

export default router;
