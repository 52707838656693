import axios from "axios";
import router  from "../router";


let apiBaseUrl = window._CONFIG['domianURL'] || '/jeecg-boot'

// 创建 axios 实例
const service = axios.create({
  baseURL: apiBaseUrl, // api base_url
  timeout: 12000000 // 请求超时时间
})

//添加请求拦截器
service.interceptors.request.use(
  (config) => {
    
     const token = localStorage.getItem('token');
    // const token ='eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE3MzEzNTY3MzUsInVzZXJuYW1lIjoiYWRtaW4ifQ.bJtU8at9ajfVoCl9ZlL37PPEymFHWibMJ7HUJE1lBVo'
    config.headers['X-Access-Token'] = token;
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);
service.interceptors.response.use(
  response => {
   const res = response.data.code; 
    if (res === 401) { 
      const ua = navigator.userAgent;
      if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) {
        window.webkit.messageHandlers.LogAgain.postMessage("LogAgain");
      } else if (/Android/.test(ua)) {
        window.handleMessage.LogAgain("LogAgain");
      }
      // 返回一个空的响应以防止后续代码执行
      return Promise.reject(new Error('Unauthorized'));
    }
    return response; // 返回正确
  },
  error => {
    // 检查错误响应的状态码
    if (error.response && error.response.data) {
      if (error.response.data.code === 401) { 
         const ua = navigator.userAgent;
        if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) {
         window.webkit.messageHandlers.LogAgain.postMessage("LogAgain");
        } else if (/Android/.test(ua)) {
         cwindow.handleMessage.LogAgain("LogAgain");
       }
        return;
      }
    }
    // 如果你想在错误处理后继续抛出错误，以便可以在调用时使用.catch捕获
    return Promise.reject(error);
  }
);

export {
  service as request
}










