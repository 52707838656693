/**
 * 空串校验
 */
export function isBlank(s) {
  return s == null || s == undefined || s == '' || s == 'undefined'
}
/**
 * 非空校验
 */
export function isNotBlank(s) {
  return s != null && s != undefined && s != ''
}
/**
 * 邮箱
 * @param {*} s
 */
export function isEmail(s) {
  return /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s)
}

/**
 * 手机号码
 * @param {*} s
 */
export function isPhone(s) {
  return /^1[0-9]{10}$/.test(s)
}

/**
 * URL地址
 * @param {*} s
 */
export function isURL(s) {
  return /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?/.test(s)
}

/**
 * 身份证校验
 * @param {*} s
 */
export function isCardReg(s) {
  return /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(s)
}

/**
 * QQ号校验
 * @param {*} s
 */
export function QQReg(s) {
  return /^[1-9][0-9]{4,}$/.test(s)
}

/**
 * 名字校验不允许输入数字
 *  @param {*} s
 */
export function isNameReg(s) {
  return /^[\u4E00-\u9FA5]+(·[\u4E00-\u9FA5]+)*$/.test(s)
}

/**
 * 脱敏数据处理  手机号 198****7004
 */
export function desensitize(s) {
  return s.substring(0, 3) + '****' + s.substring(7)
}

export function isCardName(s) {
  return /^[\u4E00-\u9FA5]+(·[\u4E00-\u9FA5]+)*$/.test(s)
}